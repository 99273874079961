/*
    Add modifiers to be able to target specific fields
 */
export function addFieldModifiers(data: any) {
  const formEl = data.getFormElem();
  const fieldWrappers = formEl.find('.mktoFieldWrap');
  fieldWrappers.each((_: any, element: any) => {
    const currClasses = element.getAttribute('class');
    const isInput = Object.keys(element.children).find((key) => element.children[key].nodeName === 'INPUT');
    const isSelect = Object.keys(element.children).find((key) => element.children[key].nodeName === 'SELECT');
    if (isInput) {
      const inputType = element.children[isInput].getAttribute('type');
      element.setAttribute('class', `${currClasses} -${inputType}`);
    } else if (isSelect) {
      element.setAttribute('class', `${currClasses} -select`);
    }
  });
}
