import invalidDomains from './invalidDomains';

export function isEmailGood(email: string) {
  for (let i = 0; i < invalidDomains.length; i++) {
    let domain = invalidDomains[i];
    if (email.indexOf(`@${domain}`) != -1) {
      return false;
    }
  }
  return true;
}
